<template>
    <div class="d-flex flex-column justify-space-between px-2">
        <v-list color="transparent" :style="{'height': height ? height : '20vh', 'overflow-y': 'auto'}">
            <template v-if="conversation.length > 0">
                <v-list-item v-for="msg of conversation"
                             :key="msg['@ConversationMessage']"
                             style="cursor:pointer;"
                             :id="`msg-${msg['@ConversationMessage']}`"
                >
                    <v-list-item-content v-on:click="receivers = [msg.UserFrom]">
                        <v-sheet color="transparent">
                            <v-card-subtitle style="font-size: 12px; line-height: 14px;" class="pa-0">{{msg.DateTime.slice(0, 20)}}</v-card-subtitle>
                            <div class="d-flex flex-row align-center">
                                <div style="width: 45%; overflow-x: auto">
                                <mini-profile :idx="msg.UserFrom" :size="32"></mini-profile>
                                </div>

                                <v-icon small v-if="msg.UsersTo && msg.UsersTo.length > 0">mdi-arrow-right</v-icon>
                                <div style="width: 45%; overflow-x: auto">
                                <mini-profile
                                        v-for="userTo of msg.UsersTo"
                                        :key="`msg-${msg['@ConversationMessage']}-userto-${userTo}}`"
                                        :idx="userTo" :size="32"></mini-profile>
                                </div>
                            </div>
                            <v-card-subtitle class="py-0 rounded-xl rounded-tl-0" style="background-color: white!important;" v-html="msg['Text']"></v-card-subtitle>
                        </v-sheet>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-else>
                <p class="text-center">Начните диалог</p>
            </template>
        </v-list>
        <v-select placeholder="Выберите получателей"
                  color="green"
                  item-color="green"
                  multiple
                  dense
                  hide-details
                  prepend-icon="mdi-account"
                  :items="employees"
                  v-model="receivers"
                  item-value="@User"
                  item-text="Name"></v-select>
        <div class="d-flex flex-row justify-center align-center py-1">
            <v-text-field
                    class="rounded-xl"
                    hide-details
                    outlined
                    color="green"
                    style="background: white"
                    dense
                    v-model="writtenText"
                    placeholder="Напишите сообщение.."
                    v-on:keyup.enter="sendMessage"
            ></v-text-field>
            <v-icon v-on:click="sendMessage" :color="writtenText ? 'green' : 'gray'" class="ml-4">mdi-send-variant</v-icon>
        </div>
    </div>

</template>

<script>
    import axios from 'axios';
    import {getURL, getWSSUrl} from "../../settings";
    import MiniProfile from "../user/MiniProfile";

    export default {
        name: "TaskConversation",
        components: {MiniProfile},
        props: ['idx', 'height'],
        data: () => {
            return {
                conversation: [],
                writtenText: null,
                receivers: [],
                participants: [],
                employees: [],
                shouldUpdate: false,
                websocket: null

            }
        },
        mounted() {
            this.loadConversation();
            this.loadEmployees();
            this.makeSocket();

        },
        updated() {
            if(this.conversation.length > 0 && this.shouldUpdate)
            {
                const lastMessage = this.conversation[this.conversation.length - 1];
                const lastMessageId = `msg-${lastMessage['@ConversationMessage']}`;
                const lastMessageElement = document.getElementById(lastMessageId);
                console.log('last id: ', lastMessageId)
                if(lastMessageElement) {
                    console.log('has element')
                    lastMessageElement.scrollIntoView({block: "nearest", inline: "nearest"});
                    this.shouldUpdate = false;
                }
            }
        },
        beforeDestroy() {
            if(this.websocket) {
                this.websocket.close();
                this.websocket = null;
            }
        },
        methods: {
            makeSocket() {
                this.websocket = new WebSocket(getWSSUrl('ws'));
                this.websocket.onopen = (e) => {
                    console.log('socket opened ', e);
                };

                this.websocket.onclose  = (e) => {
                    console.log('socket closed ', e);
                };

                this.websocket.onerror = (e) => {
                    console.log('socket on error', e)
                }

                this.websocket.onmessage = (e) => {
                    const ws_message = e.data;
                    let msg_tokens = ws_message.split(':');
                    if(msg_tokens
                        && msg_tokens.length === 2
                        && msg_tokens[0] === 'chat-updated'
                        && msg_tokens[1] === String(this.idx) ) {
                        this.loadConversation();
                    }
                    console.log('socket on message ', e.data)
                }
            },
            loadEmployees() {
                axios.get(getURL('admin/user_list'), {params: {showGuest: false}}).then(res => {
                    this.employees = res.data;
                })
            },
            loadConversation() {
                if(this.idx)
                    axios.get(getURL(`admin/docflow/conversation/${this.idx}`)).then(res => {
                        if(res.data.Messages && this.conversation.length !== res.data.Messages)
                            this.shouldUpdate = true;
                        this.conversation = res.data.Messages || [];
                    })
            },
            sendMessage() {
                axios.post(getURL('admin/docflow/conversation/send_message'), {
                    user: window.localStorage.getItem('userId'),
                    index: this.idx,
                    receivers: this.receivers,
                    text: this.writtenText,
                    content: []
                }, {withCredentials: true}).then(() => {
                    this.loadConversation();
                    this.writtenText = null
                })
            }
        }
    }
</script>

<style scoped>

</style>
