<template>
    <task-conversation height="70vh" :idx="$route.params.idx"></task-conversation>
</template>

<script>
    import TaskConversation from "../../components/tasks/TaskConversation";
    export default {
        name: "Conversation",
        components: {TaskConversation}
    }
</script>

<style scoped>

</style>
